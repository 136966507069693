import React from "react"
import Layout from "../../layout/layout"
import ReactHtmlParser from "react-html-parser"
import styled from "styled-components"
import Fade from "react-reveal/Fade"
import { homestayBanner } from "../../images"
import { graphql } from "gatsby"

const Section = styled.div`
  margin: 40px 230px;
  max-width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 1200px) {
    margin: 40px 100px;
  }

  @media (max-width: 900px) {
    margin: 40px 50px;
  }

  @media (max-width: 740px) {
    margin: 9px;
  }
`

export default function Privacy({ data }) {
  const html = data.wpPage.content
  const content = ReactHtmlParser(html)
  return (
    <div>
      <Layout pageTitle="Privacy policy" background={homestayBanner}>
        <Section id="homestayFamily">
          {content.map((data, index) => {
            return (
              <Fade>
                <div className="storyData">{data}</div>
              </Fade>
            )
          })}
        </Section>
      </Layout>
    </div>
  )
}

export const PRIVACY = graphql`
  query Privacy {
    wpPage(title: { eq: "api-privacy-policy" }) {
      content
    }
  }
`
